import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



// Import pages
import LandingMainPage from './landing-page/main';






import './index.css';
import App from './NoteApp';
import {Homepage, Aboutpage } from './App';
import reportWebVitals from './reportWebVitals';

import Amplify from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


/* 
const mdTheme = createTheme();


ReactDOM.render(
  <ThemeProvider theme={mdTheme}>
    <CssBaseline />
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingMainPage />}/>
        <Route exact path="/about" element={<Aboutpage />}/>
      </Routes>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
 */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
